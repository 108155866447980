@font-face {
    font-family: 'Inconsolata';
    src: url(./assets/fonts/Inconsolata-VariableFont.ttf);
}
@font-face {
    font-family: 'Open Sans';
    font-weight: 800;
    src: url(./assets/fonts/OpenSans-ExtraBold.ttf);
}
@font-face {
    font-family: 'Open Sans';
    font-weight: 400;
    src: url(./assets/fonts/OpenSans-Regular.ttf);
}

#root {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

a {
    font-family: 'Inconsolata', monospace;
    font-size: 1.1rem;
}

.MuiFormControl-root {
    margin: 0 !important;
    width: 100% !important;
}

.pre-wrap {
    white-space: pre-wrap;
}

.text-icon {
    font-size: 1rem !important;
}

.image {
    max-width: 100%;
}

.pointer {
    cursor: pointer;
}

.pollutant-more {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.pollutant-more:hover {
    color: #ccc;
}

.pollutant-more .MuiSvgIcon-root {
    font-size: 2rem !important;
}